import { Flex, Icon, Td, Text, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { useState } from "react";
import FormInput from "components/form/FormInput";
import schemaCumulativeSetting from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RARITY } from "views/PrizeSettings/constant";

export default function RateFusionRow ({
  cumulative,
  onSave
}) {
  const [isEdit, setIsEdit] = useState(false);
  const form = useForm({
    resolver: yupResolver(schemaCumulativeSetting()),
    defaultValues: {
      base_success_rate: cumulative ? cumulative?.base_success_rate : "",
      boosted_success_rate: cumulative ? cumulative?.boosted_success_rate : "",
    }
  });
  const {
    setValue,
    handleSubmit,
  } = form;
  const onSubmit = form.handleSubmit((data) => {
    if (onSave) {
      onSave(cumulative?.id, data);
    }
    setIsEdit(false);
  });

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  return (
    <>
      { isEdit ? (
        <FormProvider {...form}>
          <Tr>
            <Td
              width="20%"
              fontSize="sm"
              fontWeight={400}
              whiteSpace="nowrap"
            >
              {RARITY[cumulative?.rarity]}
            </Td>
            <Td>
              <FormInput
                label=""
                name="base_success_rate"
                type="number"
                placeholder="Enter number rate"
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  setValue("base_success_rate", e.target.value.replace(/[^0-9]/g, ""));
                }}
              />
            </Td>
            <Td>
              <FormInput
                label=""
                name="boosted_success_rate"
                type="number"
                placeholder="Enter number rate"
                onKeyDown={e => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                onChange={(e) => {
                  setValue("boosted_success_rate", e.target.value.replace(/[^0-9]/g, ""));
                }}
              />
            </Td>
            <Td width="10%">
              <Flex gap="10px" justifyContent="flex-end">
                <Icon
                  fontSize="30px"
                  borderRadius="8px"
                  cursor="pointer"
                  bg="#07CF84"
                  as={CheckIcon}
                  onClick={onSubmit}
                />
              </Flex>
            </Td>
          </Tr>
        </FormProvider>
      ) : (
        <Tr>
          <Td
            width="20%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {RARITY[cumulative?.rarity]}
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Text>
              {cumulative?.base_success_rate ? `${cumulative?.base_success_rate}%` : "NULL"}
            </Text>
          </Td>
          <Td
            width="35%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            <Text>
              {cumulative?.boosted_success_rate ? `${cumulative?.boosted_success_rate}%` : "NULL"}
            </Text>
          </Td>
          <Td width="10%">
            <Flex gap="10px" justifyContent="flex-end">
              <Icon
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
                onClick={() => setIsEdit(true)}
              />
            </Flex>
          </Td>
        </Tr>
      )}
    </>
  );
}
