import { Flex, Icon, Td, Tr } from "@chakra-ui/react";
import { EditIcon } from "components/Icons/EditIcon";
import { CheckIcon } from "components/Icons/CheckIcon";
import { useState } from "react";
import FormInput from "components/form/FormInput";
import schemaStartDashSetting from "./schema";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

export default function StartDashSettingRow ({
  startDash,
  onSave
}) {
  const [isEdit, setIsEdit] = useState(false);
  const form = useForm({
    resolver: yupResolver(schemaStartDashSetting()),
    defaultValues: {
      amount: startDash ? startDash?.amount : "",
      day: startDash ? startDash?.day : "",
      currency: startDash ? startDash?.currency : "",
    }
  });
  const {
    setValue,
    handleSubmit,
  } = form;
  const onSubmit = form.handleSubmit((data) => {
    if (onSave) {
      onSave(data);
    }
    setIsEdit(false);
  });

  return (
    <>
      { isEdit ? (
        <FormProvider {...form}>
          <Tr>
              <Td
                width="33%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                {startDash?.day}
              </Td>
              <Td
                width="33%"
                fontSize="sm"
                fontWeight={400}
                whiteSpace="nowrap"
              >
                <FormInput
                  label=""
                  name="amount"
                  type="number"
                  placeholder="Enter number co-op free ticket"
                  onChange={(e) => {
                    setValue("amount", e.target.value);
                  }}
                />
              </Td>
              <Td width="33%">
                <Flex gap="10px" justifyContent="flex-end">
                  <Icon
                    fontSize="30px"
                    borderRadius="8px"
                    cursor="pointer"
                    bg="#07CF84"
                    as={CheckIcon}
                    onClick={onSubmit}
                  />
                </Flex>
              </Td>
          </Tr>
        </FormProvider>
      ) : (
        <Tr>
          <Td
            width="33%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {startDash?.day}
          </Td>
          <Td
            width="33%"
            fontSize="sm"
            fontWeight={400}
            whiteSpace="nowrap"
          >
            {startDash?.amount}
          </Td>
          <Td width="33%">
            <Flex gap="10px" justifyContent="flex-end">
              <Icon
                fontSize="30px"
                borderRadius="8px"
                p="7px"
                cursor="pointer"
                bg="#1480FF"
                as={EditIcon}
                onClick={() => setIsEdit(true)}
              />
            </Flex>
          </Td>
        </Tr>
      )}
    </>
  );
}
