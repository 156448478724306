import {
  Flex,
  Grid,
  Text
} from "@chakra-ui/react";
import FormInput from "components/form/FormInput";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import moment from "moment";
import FormLabelComponent from "components/form/FormLabel";
import FormDatePicker from "components/form/FormDatePicker";
import { convertToIsoString } from "views/Event/help";

export default function ConditionNotice({ isLoading, setParams, params, categories }) {
  const form = useForm();
  const { setValue, handleSubmit } = form;
  const formatResult = "YYYY-MM-DD HH:mm:ss";
  const dateFormat = "dd MMM. YYYY HH:mm:ss";

  const onSubmit = (data) => {
    const dataSubmit = {
      keyword: data?.keyword,
      fromDate: data?.notice_start_time ? convertToIsoString(data?.notice_start_time) : '',
      toDate: data?.notice_end_time ? convertToIsoString(data?.notice_end_time) : '',
      category: data?.category,
    }

    if (!isLoading) {
      const newParams = {
        ...params,
        ...dataSubmit,
        page: 1,
      };
      setParams(newParams);
    }
  };

  return (
    <Flex
      gap="16px"
      flexWrap="wrap"
      direction="column"
      justifyContent="flex-start"
      w="100%"
      alignItems="flex-start"
    >
      <FormProvider {...form} w="100%">
        <form
          style={{
            width: "100%",
            minWidth: "600px",
            display: 'flex',
            justifyContent: 'space-between',
            gap: '8px'
          }}
          onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="row" gap={6}>
            <FormInput
              name="keyword"
              label="Search"
              placeholder="Search by key word"
              w="300px"
              onChange={(e) => {
                const keyword = e.target.value.trim();
                setValue("keyword", keyword);
                if (keyword) {
                  handleSubmit(onSubmit)();
                }
              }}
            />
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title="From"
              />
              <FormDatePicker
                name="notice_start_time"
                showTimeSelect
                dateFormat={dateFormat}
                w="200px"
                h="40px"
                onChange={(e) => {
                const time = moment(e).format(formatResult);
                  setValue("notice_start_time", time);
                  handleSubmit(onSubmit)();
                }}
              />
            </Flex>
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title="To"
              />
              <FormDatePicker
                name="notice_end_time"
                showTimeSelect
                dateFormat={dateFormat}
                w="200px"
                h="40px"
                onChange={(e) => {
                const time = moment(e).format(formatResult);
                  setValue("notice_end_time", time);
                  handleSubmit(onSubmit)();
                }}
              />
            </Flex>
            <FormSelect
              w="200px"
              name="category"
              label="Filter by Category"
              options={[{ label: "All", value: "" }, ...categories]}
              onChange={(e) => {
                setValue("category", e.target.value);
                handleSubmit(onSubmit)();
              }}
            />
          </Flex>
          <Text
            cursor="pointer"
            color="#1480FF"
            fontSize="14px"
            fontWeight="500"
            alignSelf="flex-end"
            onClick={() => {
            setValue("keyword", "");
            setValue("notice_start_time", "");
            setValue("notice_end_time", "");
            setValue("category", "");
            handleSubmit(onSubmit)();
            }}
          >
            Reset
          </Text>
        </form>
      </FormProvider>
    </Flex>
  );
}
