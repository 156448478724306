import {
  CloseButton,
  Flex,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createBanner, getEventsInComing, updateBanner } from "api/ads.api";
import { getGachaInComing } from "api/gacha.api";
import ButtonType from "components/Button";
import FormDatePicker from "components/form/FormDatePicker";
import FormInput from "components/form/FormInput";
import FormLabelComponent from "components/form/FormLabel";
import FormSelect from "components/form/FormSelect";
import FormUpload from "components/form/FormUpload";
import { NUMBER } from "constants/enum";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { uploadImageToServer } from "utils/uploadImage";
import { ERROR_CREATE_EVENT } from "views/Event/EventManagement/constant";
import { convertToIsoString, convertToUTCTime } from "views/Event/help";
import SelectMachineModal from "../components/SelectMachineModal";
import SelectPrizeModal from "../components/SelectPrizeModal";
import {
  DESTINATION_KEY,
  DESTINATION_OPTIONS,
  EVENT_TYPE,
  PLACEMENT_OPTIONS,
  SPECIFIC_ITEM_OPTIONS,
  TRANS_LABEL,
  TRIGGER_KEY,
  TRIGGER_OPTIONS,
} from "../constants";
import schemaBanner from "./schema";

const formatResult = "YYYY-MM-DD HH:mm:ss";
const dateFormat = "dd MMM. YYYY HH:mm:ss";

export default function CreateAndUpdateBanner({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const [trans, setTrans] = useState("en");
  const [nameImage, setNameImage] = useState("");
  const [checkedPrize, setCheckedPrize] = useState([]);
  const [checkedManchine, setCheckedManchine] = useState([]);
  const [eventIncoming, setEventIncoming] = useState([]);
  const [gachaIncoming, setGachaIncoming] = useState([]);
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaBanner()),
  });
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = form;

  // WATCH VALUES
  const destination = watch("destination");
  const trigger = watch("trigger");
  const eventId = watch("event_id");

  const event = useMemo(() => {
    return eventIncoming?.find((event) => event.id === eventId);
  }, [eventId, eventIncoming]);

  const {
    isOpen: isOpenSelectPrize,
    onOpen: onOpenSelectPrize,
    onClose: onCloseSelectPrize,
  } = useDisclosure();
  const {
    isOpen: isOpenSelectManchine,
    onOpen: onOpenSelectManchine,
    onClose: onCloseSelectManchine,
  } = useDisclosure();

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  const handleGetEventsInComing = async () => {
    try {
      const { data } = await getEventsInComing();
      if (data?.success && data) {
        setEventIncoming(data?.data || []);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };
  const handleGetGachaInComing = async () => {
    try {
      const { data } = await getGachaInComing();
      if (data?.success && data) {
        setGachaIncoming(data?.data || []);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      start_date: convertToIsoString(data?.start_date),
      end_date: convertToIsoString(data?.end_date),
      placement: data?.placement,
      destination: data?.destination,
      image: data?.image,
      gift: data?.trigger ?? null,
      specific_item: {},
    };

    // DATA SPECIFIC ITEM
    let specificItemData = {};
    switch (destination) {
      case DESTINATION_KEY.SHOP:
        specificItemData = {
          shop: {
            point: data?.point,
          },
        };
        break;
      case DESTINATION_KEY.GACHA:
        specificItemData = {
          gacha: {
            gacha_id: data?.gacha_id,
          },
        };
        break;
      case DESTINATION_KEY.RANKING:
        specificItemData = {
          ranking: {
            leader_board: data?.leader_board,
          },
        };
        break;
      case DESTINATION_KEY.PVE:
        specificItemData = {
          prizes: checkedPrize?.map((prize) => ({
            prize_id: prize?.id,
            prize_name: prize?.name,
          })),
        };
        break;
      case DESTINATION_KEY.EVENT:
        specificItemData = {
          event: {
            event_id: data?.event_id,
          },
        };
        break;
      case DESTINATION_KEY.URL:
        specificItemData = {
          url: {
            link: data?.link,
          },
        };
        break;
      case DESTINATION_KEY.MODAL:
        specificItemData = {
          modal: {
            modal_title: data?.modal_title,
            modal_background: data?.modal_background,
          },
        };
        break;
      case DESTINATION_KEY.MARKET_PLACE:
        specificItemData = {
          market_place: {
            link: data?.link,
          },
        };
        break;
      default:
        break;
    }

    // DATA TRIGGER
    if (data?.trigger === TRIGGER_KEY.GRANT_SPECIFIC_ITEM) {
      dataSubmit.trigger_detail = {
        free_ticket:
          event?.event_type === EVENT_TYPE.STANDARD_EVENT
            ? data?.free_ticket
            : null,
        invitation_card:
          event?.event_type === EVENT_TYPE.SKILL_RANK_EVENT
            ? data?.invitation_card
            : null,
      };
    } else if (data?.trigger === TRIGGER_KEY.GRANT_SPECIFIC_POINT) {
      const total = (data?.sphere || 0) + (data?.orb || 0) + (data?.cp || 0);
      if (total <= 0) {
        toast({
          description:
            "At least one of sphere, orb, or cp must be greater than 0",
          status: "error",
          position: "bottom-right",
        });
        return;
      }
      dataSubmit.trigger_detail = {
        cp: data?.cp,
        orb: data?.orb,
        sphere: data?.sphere,
      };
    }

    dataSubmit.specific_item = specificItemData;

    try {
      if (idEdit) {
        const res = await updateBanner(dataDetail?.id, dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Ads Banner Edited Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose();
        } else {
          toast({
            description:
              ERROR_CREATE_EVENT[res?.data?.message] ||
              ERROR_CREATE_EVENT[res?.data?.messages[0]] ||
              res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      } else {
        const res = await createBanner(dataSubmit);
        if (res?.data?.success) {
          toast({
            title: "Ads Banner Created Successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          handleCreateOrUpdateSuccess();
          onClose();
        } else {
          toast({
            description:
              ERROR_CREATE_EVENT[res?.data?.message] ||
              ERROR_CREATE_EVENT[res?.data?.messages[0]] ||
              res?.data?.messages[0],
            status: "error",
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const handleImageChange = async (event, name) => {
    const image = event[0];
    await uploadImageToServer(image, toast, setValue, name, setNameImage);
  };

  const handleDragImage = async (file, name) => {
    if (file.length > NUMBER.ONE) {
      toast({
        description: "The number of uploaded images is greater than one.",
        status: "error",
        position: "bottom-right",
      });
      return false;
    }
    const image = file && file[0];
    await uploadImageToServer(image, toast, setValue, name, setNameImage);
  };

  const deleteImage = () => {
    setValue("image", null);
  };

  const handleSelectPrize = (prizes) => {
    if (Array.isArray(prizes)) {
      setValue("prizes", prizes?.map((prize) => prize?.name)?.join(", "));
      setCheckedPrize(prizes);
    }
  };
  const handleSelectManchine = (prizes) => {
    if (Array.isArray(prizes)) {
      setCheckedManchine(prizes);
    }
  };
  const handleRemoteSelectPrize = (prize) => {
    if (!prize) return;
    setCheckedPrize((preState) => {
      const prizes = [...preState.filter((item) => item.id !== prize.id)];
      setValue("prizes", prizes?.map((prize) => prize?.name)?.join(", "));
      return prizes;
    });
  };
  const handleRemoteSelectManchine = (prize) => {
    if (!prize) return;
    setCheckedManchine((preState) => [
      ...preState.filter((item) => item.id !== prize.id),
    ]);
  };

  const renderListPrizes = useMemo(() => {
    return (
      <Flex gap={"10px"} wrap={"wrap"} align={"center"}>
        {checkedPrize?.map((prize) => (
          <Flex
            key={prize?.id}
            backgroundColor={"#fff4e0"}
            p={"10px 5px 10px 20px"}
            rounded={"8px"}
            gap={"20px"}
            align={"center"}
          >
            {prize?.name}
            <CloseButton onClick={() => handleRemoteSelectPrize(prize)} />
          </Flex>
        ))}
      </Flex>
    );
  }, [checkedPrize]);
  const renderListManchine = useMemo(() => {
    return (
      <Flex gap={"10px"} wrap={"wrap"} align={"center"}>
        {checkedManchine?.map((machine) => (
          <Flex
            key={machine?.id}
            backgroundColor={"#fff4e0"}
            p={"10px 5px 10px 20px"}
            rounded={"8px"}
            gap={"20px"}
            align={"center"}
          >
            {machine?.name}
            <CloseButton onClick={() => handleRemoteSelectManchine(machine)} />
          </Flex>
        ))}
      </Flex>
    );
  }, [checkedManchine]);

  const renderSpecificItem = useMemo(() => {
    const options = SPECIFIC_ITEM_OPTIONS[destination];
    const settingsObj = {
      [DESTINATION_KEY.SHOP]: {
        name: "point",
        placeholder: transLabel("select_specific_currency"),
      },
      [DESTINATION_KEY.GACHA]: {
        name: "gacha_id",
        placeholder: transLabel("select_specific_currency"),
        options: gachaIncoming
          ? gachaIncoming.map((gacha) => ({
              label: gacha?.name,
              value: gacha?.id,
            }))
          : [],
      },
      [DESTINATION_KEY.RANKING]: {
        name: "leader_board",
        placeholder: transLabel("select_specific_ranking"),
      },
      [DESTINATION_KEY.URL]: {
        name: "link",
        label: transLabel("url"),
        placeholder: transLabel("select_specific_url"),
      },
      [DESTINATION_KEY.MARKET_PLACE]: {
        name: "link",
        label: transLabel("url"),
        placeholder: transLabel("select_specific_url"),
      },
      [DESTINATION_KEY.PVE]: {
        name: "prizes",
        label: transLabel("specific_item"),
        placeholder: transLabel("select_specific_prize"),
      },
      [DESTINATION_KEY.EVENT]: {
        name: "event_id",
        label: transLabel("specific_item"),
        placeholder: transLabel("event_placeholder"),
        options: eventIncoming
          ? eventIncoming.map((event) => ({
              label: event?.name,
              value: event?.id,
            }))
          : [],
      },
    };
    const setting = settingsObj[destination];

    if (Array.isArray(options) || Array.isArray(setting?.options)) {
      return (
        <FormSelect
          maxW="100%"
          name={setting.name}
          label={setting?.label || transLabel("specific_item")}
          placeholder={setting?.placeholder}
          options={setting?.options || options}
          defaultValue={""}
          onChange={(e) => {
            setValue(setting.name, e.target.value);
          }}
          isRequired
        />
      );
    } else if (
      destination === DESTINATION_KEY.URL ||
      destination === DESTINATION_KEY.MARKET_PLACE
    ) {
      return (
        <FormInput
          maxW="100%"
          name={setting.name}
          label={setting?.label}
          placeholder={setting.placeholder}
          isRequired
        />
      );
    } else if (destination === DESTINATION_KEY.MODAL) {
      return (
        <>
          <FormInput
            maxW="100%"
            name="modal_title"
            label={transLabel("modal_title")}
            placeholder={transLabel("select_specific_model")}
            isRequired
          />
          <FormUpload
            label="Select image file to upload"
            title={transLabel("modal_background")}
            name="modal_background"
            type="IMAGE"
            accept={"image/*"}
            isRequired
            textButton={nameImage}
            handleOnChange={(e) => handleImageChange(e, "modal_background")}
            handleDragImage={(val) => handleDragImage(val, "modal_background")}
            deleteImage={() => deleteImage("modal_background")}
          />
          {!watch("modal_background") && (
            <Text>*Currently support .png, .jpg less than 5mb </Text>
          )}
        </>
      );
    } else if (destination === DESTINATION_KEY.PVE) {
      return (
        <>
          <FormInput
            maxW="100%"
            name={setting?.name}
            label={setting?.label}
            placeholder={setting?.placeholder}
            readOnly
            style={{ cursor: "pointer" }}
            onClick={onOpenSelectPrize}
            isRequired
          />
          {renderListPrizes}
        </>
      );
    } else if (destination === DESTINATION_KEY.SPECTATE) {
      return (
        <>
          <FormInput
            maxW="100%"
            name="modal_title"
            label={setting?.label}
            placeholder={setting?.placeholder}
            readOnly
            style={{ cursor: "pointer" }}
            onClick={onOpenSelectManchine}
          />
          {renderListManchine}
        </>
      );
    } else if (destination === DESTINATION_KEY.EVENT) {
      return (
        <FormSelect
          maxW="100%"
          name={setting?.name}
          label={setting?.label}
          placeholder={setting?.placeholder}
          options={setting?.options || []}
          defaultValue={""}
          onChange={(e) => {
            setValue(setting?.name, e.target.value);
          }}
          isRequired
        />
      );
    }
  }, [
    destination,
    dataDetail,
    renderListPrizes,
    renderListManchine,
    eventIncoming,
    gachaIncoming,
    errors,
  ]);

  const renderForm = () => {
    return (
      <Flex direction="column" w="100%" gap={4}>
        <Grid gap={4}>
          <Flex alignItems="flex-start" gap="16px" w="100%">
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title={transLabel("start_date")}
                isRequired
              />
              <FormDatePicker
                name="start_date"
                showTimeSelect
                dateFormat={dateFormat}
                placeholder="dd/mm/yyyy hh:mm"
                h="40px"
                minDate={moment().toDate()}
                isShowTodayBtn
                onChange={(e) => {
                  const time = moment(e).format(formatResult);
                  setValue("start_date", time);
                }}
              />
            </Flex>
            <Flex direction="column" gap="8px" w="100%">
              <FormLabelComponent
                m="0px"
                title={transLabel("end_date")}
                isRequired
              />
              <FormDatePicker
                name="end_date"
                showTimeSelect
                dateFormat={dateFormat}
                placeholder="dd/mm/yyyy hh:mm"
                h="40px"
                minDate={moment().toDate()}
                isShowTodayBtn
                onChange={(e) => {
                  const time = moment(e).format(formatResult);
                  setValue("end_date", time);
                }}
              />
            </Flex>
          </Flex>
          <FormSelect
            maxW="100%"
            name="placement"
            label={transLabel("placement")}
            placeholder={transLabel("select_placement")}
            options={PLACEMENT_OPTIONS}
            onChange={(e) => {
              setValue("placement", e.target.value);
            }}
            isRequired
          />
          <FormUpload
            w="auto"
            h="auto"
            label="Select image file to upload"
            title={transLabel("image")}
            name="image"
            type="IMAGE"
            accept={"image/*"}
            isRequired
            textButton={nameImage}
            handleOnChange={(e) => handleImageChange(e, "image")}
            handleDragImage={(val) => handleDragImage(val, "image")}
            deleteImage={() => deleteImage("image")}
          />
          {!watch("image") && (
            <Text>*Currently support .png, .jpg less than 5mb </Text>
          )}
          <FormSelect
            maxW="100%"
            name="destination"
            label={transLabel("destination")}
            placeholder={transLabel("select_target")}
            options={DESTINATION_OPTIONS}
            defaultValue={""}
            onChange={(e) => {
              setValue("destination", e.target.value);
              // Handle set Trigger value
              if (e.target.value === DESTINATION_KEY.EVENT) {
                setValue("trigger", TRIGGER_KEY.GRANT_SPECIFIC_ITEM);
              } else
                setValue(
                  "trigger",
                  trigger === TRIGGER_KEY.GRANT_SPECIFIC_ITEM
                    ? TRIGGER_KEY.GRANT_SPECIFIC_POINT
                    : trigger
                );
            }}
            isRequired
          />
          {renderSpecificItem}
          <FormSelect
            maxW="100%"
            name="trigger"
            label={transLabel("trigger")}
            placeholder={transLabel("select_trigger")}
            options={
              destination === DESTINATION_KEY.EVENT
                ? TRIGGER_OPTIONS?.filter(
                    (item) => item.value === TRIGGER_KEY.GRANT_SPECIFIC_ITEM
                  )
                : TRIGGER_OPTIONS?.filter(
                    (item) => item.value !== TRIGGER_KEY.GRANT_SPECIFIC_ITEM
                  )
            }
            onChange={(e) => {
              setValue("trigger", e.target.value);
            }}
          />
          {trigger === TRIGGER_KEY.GRANT_SPECIFIC_ITEM && (
            <Flex alignItems="flex-start" gap="16px" w="100%">
              <Flex direction="column" gap="8px" w="100%">
                <FormInput
                  type="number"
                  maxW="100%"
                  name="free_ticket"
                  label={transLabel("free_ticket")}
                  placeholder={transLabel("free_ticket_placeholder")}
                  disabled={event?.event_type !== EVENT_TYPE.STANDARD_EVENT}
                />
              </Flex>
              {destination === DESTINATION_KEY.EVENT && (
                <Flex direction="column" gap="8px" w="100%">
                  <FormInput
                    type="number"
                    maxW="100%"
                    name="invitation_card"
                    label={transLabel("invitation_card")}
                    placeholder={transLabel("invitation_card_placeholder")}
                    disabled={event?.event_type !== EVENT_TYPE.SKILL_RANK_EVENT}
                  />
                </Flex>
              )}
            </Flex>
          )}
          {trigger === TRIGGER_KEY.GRANT_SPECIFIC_POINT && (
            <Flex alignItems="flex-start" gap="16px" w="100%" wrap="wrap">
              <Flex
                direction="column"
                gap="8px"
                w="calc(50% - 8px)"
                minWidth="250px"
              >
                <FormInput
                  type="number"
                  maxW="100%"
                  name="sphere"
                  label={transLabel("sphere")}
                  placeholder={transLabel("sphere_placeholder")}
                />
              </Flex>
              <Flex
                direction="column"
                gap="8px"
                w="calc(50% - 8px)"
                minWidth="250px"
              >
                <FormInput
                  type="number"
                  maxW="100%"
                  name="orb"
                  label={transLabel("orb")}
                  placeholder={transLabel("orb_placeholder")}
                />
              </Flex>
              <Flex
                direction="column"
                gap="8px"
                w="calc(50% - 8px)"
                minWidth="250px"
              >
                <FormInput
                  type="number"
                  maxW="100%"
                  name="cp"
                  label={transLabel("cp")}
                  placeholder={transLabel("cp_placeholder")}
                />
              </Flex>
            </Flex>
          )}
        </Grid>
      </Flex>
    );
  };

  useEffect(() => {
    if (dataDetail) {
      reset({
        start_date: dataDetail?.start_date
          ? convertToUTCTime(dataDetail?.start_date)
          : null,
        end_date: dataDetail?.end_date
          ? convertToUTCTime(dataDetail?.end_date)
          : null,
        placement: dataDetail?.placement,
        image: dataDetail?.image,
        destination: dataDetail?.destination,
        trigger: dataDetail?.gift,

        // SPECIFIC ITEM
        point: dataDetail?.specific_item?.shop?.point || "",
        gacha_id: dataDetail?.specific_item?.gacha?.gacha_id || "",
        leader_board: dataDetail?.specific_item?.ranking?.leader_board || "",
        link:
          dataDetail?.specific_item?.url?.link ||
          dataDetail?.specific_item?.market_place?.link ||
          "",
        modal_title: dataDetail?.specific_item?.modal?.modal_title || "",
        modal_background:
          dataDetail?.specific_item?.modal?.modal_background || "",
        prizes: dataDetail?.specific_item?.prizes
          ? dataDetail?.specific_item?.prizes
              .map((prize) => prize.prize_name)
              .join(", ")
          : [],
        event_id: dataDetail?.specific_item?.event?.event_id || "",

        // TRIGGER
        free_ticket: dataDetail?.trigger_detail?.free_ticket || "",
        invitation_card: dataDetail?.trigger_detail?.invitation_card || "",
        sphere: dataDetail?.trigger_detail?.sphere || "",
        orb: dataDetail?.trigger_detail?.orb || "",
        cp: dataDetail?.trigger_detail?.cp || "",
      });

      // INIT CheckedPrize
      if (Array.isArray(dataDetail?.specific_item?.prizes)) {
        setCheckedPrize(
          dataDetail?.specific_item?.prizes?.map((prize) => ({
            id: prize?.prize_id,
            name: prize?.prize_name,
          }))
        );
      }
    } else {
      reset({
        start_date: "",
        end_date: "",
        placement: "",
        image: "",
        destination: "",
        trigger: "",
        point: "",
        gacha_id: "",
        leader_board: "",
        link: "",
        modal_title: "",
        modal_background: "",
        prizes: "",
        event_id: "",

        // TRIGGER
        free_ticket: "",
        invitation_card: "",
        sphere: "",
        orb: "",
        cp: "",
      });
    }
  }, [dataDetail, reset]);

  useEffect(() => {
    handleGetEventsInComing();
    handleGetGachaInComing();
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="700px" overflowY="auto">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color={textColor}
          >
            {idEdit ? transLabel("title_edit") : transLabel("title_add")}
          </ModalHeader>
          <ModalCloseButton />
          <FormProvider {...form}>
            <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
              <ModalBody p="24px">{renderForm()}</ModalBody>
              <ModalFooter
                borderTop="1px solid #EEEFF2"
                w="100%"
                textAlign="center"
                margin="auto"
                gap="20px"
              >
                <ButtonType
                  mt={4}
                  btnType="primary-new-outline"
                  m="auto"
                  w="100%"
                  h="46px"
                  borderRadius="5px"
                  form="machine-form"
                  onClick={onClose}
                >
                  {transLabel("cancel")}
                </ButtonType>
                <ButtonType
                  mt={4}
                  type="submit"
                  m="auto"
                  w="100%"
                  h="46px"
                  borderRadius="5px"
                  form="machine-form"
                  isLoading={isSubmitting}
                >
                  {idEdit ? transLabel("edit") : transLabel("add")}
                </ButtonType>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
      {isOpenSelectPrize && (
        <SelectPrizeModal
          isOpen={isOpenSelectPrize}
          onClose={onCloseSelectPrize}
          handleSelectPrize={handleSelectPrize}
          checkedPrize={checkedPrize}
          setCheckedPrize={setCheckedPrize}
        />
      )}
      {isOpenSelectManchine && (
        <SelectMachineModal
          isOpen={isOpenSelectManchine}
          onClose={onCloseSelectManchine}
          handleSelectPrize={handleSelectManchine}
          checkedPrize={checkedManchine}
          setCheckedPrize={setCheckedManchine}
        />
      )}
    </>
  );
}
